<div class="container-fluid ml-n2">
  <div v-if="isTestMode" class="row text-center">
    <div class="col-12">
      <label class="sub-title-style" style="color: red;">{{ $t('message.label_title_test_mode') }}</label>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <b-nav tabs class="ml-2 mr-2" v-if="useVehicleScreenTabs">
        <!-- Vehicle 画面用のヘッダタブ -->
        <b-nav-item disabled>{{ reproFuncTabTitle }}</b-nav-item>

        <!-- TODO v300 => v230 merge マスク対応 -->
        <!-- TODO vue3よりhref="#"でページ遷移する仕様になった。対策として、@click⇒@click.prevent.stopとすること -->
        <!-- <b-nav-item v-if="!isTechnicalHq" :active="activeTabArray[0]" @click="switchFunctionTab('rpr')">
          {{ $t('message.label_vehicle_system_tab_reprogramming') }}</b-nav-item> -->
        <!-- <b-nav-item v-if="isDisplayTabs" :active="activeTabArray[1]" @click="switchFunctionTab('diag')">
          {{ $t('message.label_vehicle_system_tab_dtc') }}</b-nav-item> -->

      </b-nav>
      <b-nav tabs class="ml-2 mr-2" v-else>
        <!-- System 画面用のヘッダタブ -->
        <b-nav-item disabled>{{ $t('message.header_vehicle_system_tab_flash') }}</b-nav-item>
      </b-nav>
    </div>
    <div class="col-12">
      <!-- SU法規対象のシステム一覧では、許諾状態が更新されたときにテーブルを取得し直す -->
      <!-- SU法規対象のシステム一覧では、法規適合化操作が行われたときテーブルを更新する -->
      <!-- 許諾更新後のテーブル再取得成功でダイアログを表示させない特別対応 -->
      <router-view :tableData="systemTables" @selectReproTableRow="clickReproTableRow"
        @setVehicleTabsAvailable="setVclScreenTabsAvailable" @switchPublishmentMode="switchPublishmentMode" @setModalQueueAvailable="setModalQueueAvailable"
        @refreshAllReproSystems="refreshAllReproSystems" @runModalQueue="runModalQueue"
        @changeTableTypes="changeReproFuncTabName" />
    </div>
  </div>

  <!-- 機能向上・復元のパスワード確認ダイアログ -->
  <div>
    <b-modal v-model="showModalPassword" id="modal-pfc-file-password-input" size="lg" header-bg-variant="secondary" header-text-variant="light"
      no-stacking no-close-on-backdrop centered no-close-on-esc @ok="checkPfcFilePassword()">
      <template v-slot:header>
        <div class="text-center w-100">
          {{ $t("message.messege_title_password") }}
        </div>
      </template>
      <template v-slot:default>
        <div class="container-fluid mt-2 mb-2">
          <div class="row no-gutters">
            <!-- 説明文 -->
            <div class="mb-3">{{ $t('message.messege_repro_merchantability_password') }}</div>
            <!-- 入力フォーム -->
            <div class="col-12">
              <b-form-group class="form-group">
                <b-input-group>
                  <b-form-input type="password" v-model="pfcFilePassword" size="sm" autocomplete="off">
                    {{ pfcFilePassword }}
                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="text-center w-100">
          <b-button size="sm" variant="outline-primary" class="ml-2 mr-2" @click="showModalPassword=false">
            {{ $t('message.common_button_cancel')}}
          </b-button>
          <b-button size="sm" variant="primary" @click="checkPfcFilePassword()" class="ml-2 mr-2">
            {{ $t("message.common_button_ok") }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
  <processing-view :processing="processing"></processing-view>
</div>