<div class="container">
  <div class="init-password-container">
    <div class="row row-90">
      <div class="col-12 text-center">
        <label class="label-sub-header">{{ $t("message.header_sub_forgot_password") }}</label>
        <hr class="hr-line-100">
        <!-- TODO v300 => v230 merge マスク対応 -->
        <!-- 文言が返ってきていないためph2系では下記文言をコメントアウトにします -->
        <!-- <label class="label-sm" style="max-width: 480px;">{{ $t("message.header_sub_forgot_message") }}</label> -->
      </div>

      <div class="col-12">
        <div class="mx-auto" style="max-width: 480px;">
          <b-form-group class="form-group">
            <label class="label-sm">{{ $t("message.label_user_id") }}</label>
            <b-form-input type="text" id="id-username" v-model="user_name" autocomplete="off" size="sm" />
          </b-form-group>
        </div>
      </div>
      <div class="row col-12">
        <div class="col-6">
          <b-form-group class="d-flex justify-content-end form-group">
            <label class="label-sm">{{ $t("message.label_forgot_first_name") }}</label>
            <b-form-input style="max-width: 240px;" type="text" v-model="lastName" autocomplete="off" size="sm"
              :disabled="!isUserNameEnabled" />
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group class="form-group">
            <label class="label-sm">{{ $t("message.label_forgot_last_name") }}</label>
            <b-form-input style="max-width: 240px;" type="text" v-model="firstName" autocomplete="off" size="sm"
              :disabled="!isUserNameEnabled" />
          </b-form-group>
        </div>
      </div>
      <div class="col-12">
        <div class="text-center">
          {{ $t('message.label_or') }}
        </div>
      </div>
      <div class="col-12">
        <div class="mx-auto" style="max-width: 480px;">
          <b-form-group class="form-group">
            <label class="label-sm">{{ $t("message.label_user_email") }}</label>
            <b-form-input type="email" v-model="email" autocomplete="off" size="sm" :disabled="!isUserEmailEnabled" />
          </b-form-group>
        </div>
      </div>

      <div class="col-12 text-center mb-3 mt-3">
        <b-button variant="outline-primary" v-on:click="onTapBackButton">
          {{ $t('message.common_button_back') }}
        </b-button>
        <b-button :disabled="!isManadatoryFilled" variant="primary" class="ml-4" v-on:click="executeInitPassword">
          {{ $t("message.button_init_password") }}
        </b-button>
      </div>
    </div>
  </div>

  <processing-view :processing="processing"></processing-view>
</div>