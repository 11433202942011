export default {
  props: {
    inActive: { type: Boolean, required: false, default: false },
    pageSize: { type: Number, required: false, default: 999 },
    initPage: { type: Number, required: false, default: 1 },
    totalPageLength: { type: Number, required: false, default: 1 },
    usePaging: { type: Boolean, required: false, default: false },
    // TODO v300 => v230 merge マスク対応 ----------
    // inRprService: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      currentPage: 0,
      activePage: 0,
      activeItems: null,
      displayCount: '-',
    };
  },

  watch: {
    totalPageLength: {
      immediate: true,
      handler(val) {
        if (this.currentPage === 0) {
          this.currentPage = this.initPage;
        }
        this.updateDisplayCount(val);
      }
    },
  },

  methods: {
    onNext: function () {
      if (this.currentPage * this.pageSize < this.itemsLength) {
        this.currentPage++;
        this.$emit("selecedPage", this.currentPage);
        this.updateDisplayCount(this.totalPageLength);
      }
    },
    onLastPage: function () {
      this.currentPage = this.totalPage;
      this.$emit("selecedPage", this.currentPage);
      this.updateDisplayCount(this.totalPageLength);
    },
    onPrev: function () {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.$emit("selecedPage", this.currentPage);
        this.updateDisplayCount(this.totalPageLength);
      }
    },
    onFirstPage: function () {
      this.currentPage = 1;
      this.$emit("selecedPage", this.currentPage);
      this.updateDisplayCount(this.totalPageLength);
    },
    goto(page) {
      this.currentPage = page;
      this.$emit("selecedPage", this.currentPage);
      this.updateDisplayCount(this.totalPageLength);
    },

    updateDisplayCount(length) {
      let display = '-';
      if (length === 0) {
        this.displayCount = 0;
      }
      else {
        let firstCount = this.pageSize * (this.currentPage - 1);

        let lastCount;
        let pageCount = Math.floor(length / this.pageSize);
        const surplus = length % this.pageSize;

        let isLastPage = false;
        if (pageCount === 0) {
          isLastPage = true;
        }
        if (surplus) {
          pageCount++;
        }
        if (pageCount === this.currentPage) {
          isLastPage = true;
        }

        // 最終ページのチェック
        if (isLastPage) {
          let itemCount = surplus;
          if (surplus === 0) {
            itemCount =  this.pageSize;
          }
          lastCount = firstCount + itemCount;

          if (firstCount === 0) {
            firstCount = 1;
          }
          else {
            firstCount++;
          }
        }
        else {
          lastCount = this.currentPage * this.pageSize;
          firstCount++;
        }

        display = String(firstCount) + '-' + String(lastCount);
        this.displayCount = display;
      }
    },

    initializePagenation() {
      // currentPage を初期ページに戻す
      this.currentPage = this.initPage;
      // DisplayCountを初期値に戻す
      this.updateDisplayCount(this.totalPageLength);
    }
  },

  computed: {
    isPrevActive() {
      return this.currentPage > 1;
    },
    isNextActive() {
      return this.currentPage < this.totalPage;
    },
    totalPage() {
      const total = Math.ceil(this.itemsLength / this.pageSize);
      return total;
    },
    itemsLength() {
      let total = 0;
      if (this.totalPageLength) {
        total = this.totalPageLength;
      }
      return total;
    },

    pageItems() {
      const valPrev = this.currentPage > 1 ? (this.currentPage - 1) : 1;
      const valNext = this.currentPage < this.totalPage ? (this.currentPage + 1) : this.totalPage;
      const extraPrev = valPrev === 3 ? 2 : null;
      const extraNext = valNext === (this.totalPage - 2) ? (this.totalPage - 1) : null;
      const dotsBefore = valPrev > 3 ? 2 : null;
      const dotsAfter = valNext < (this.totalPage - 2) ? (this.totalPage - 1) : null;

      const output = [];
      this.activePage = 0;

      for (let i = 1; i <= this.totalPage; i += 1) {
        if ([1, this.totalPage, this.currentPage, valPrev, valNext, extraPrev, extraNext, dotsBefore, dotsAfter].includes(i)) {
          let active = false;
          if (this.currentPage === i) {
            active = true;
            this.activePage = i;
          }
          output.push({
            label: i,
            active: active,
            disable: [dotsBefore, dotsAfter].includes(i)
          });
        }
      }

      return output;
    },
  },
};