<div>
  <div class="row no-gutters" ref="tableHeader">
    <div class="col-auto">
      <slot name="subButtonContainer"></slot>
    </div>
    <div class="col pagination justify-content-end" v-bind:class="{ 'tabs-bottom': hasTabs }">
      <slot name="contentRightEnd"></slot>
    </div>
  </div>

  <hr v-show="!hasTabs" class="hr-line-100">

  <div v-if="type === 'simple'">
    <!-- simple table  -->
    <div class="table-responsive">
      <table class="table table-sm overflow-auto" v-bind:class="getTableStyle()">
        <tr v-if="columns">
          <th v-for="(column, i) in columns" :key="i" :style="{ 'min-width': column.minWidth}">
            <div v-if="useSort || column.useSort" class="table-header-cursor" @click="sort(column)">
              <span v-if="column.useHtmlBind" v-html="column.label"></span>
              <span v-else>| {{ $t(column.label) }}</span>
              <span v-if="currentSort == column.name" v-html="getSortIcon()"></span>
            </div>
            <div v-else>
              <span v-if="column.useHtmlBind" v-html="column.label"></span>
              <span v-else>| {{ $t(column.label) }}</span>
            </div>
          </th>
        </tr>
        <tr v-for="(data, index) in (sortedActivity)" :key="index" v-bind:class="{ 'body-event-none' : !rowSelect }"
          v-on:click="rowClicked(data)">
          <td v-for="(column, i) in columns" :key="i" v-bind:class="getTdStyle(data.bgStyle, index)">
            <span v-if="column.useResource" :id="`popover${index}${i}`"
              v-bind:class="getColumnClasses(data, column.name)"
              @mouseover="onMouseHover(`popover${index}${i}`, column.name, data[column.name])"
              v-html="$t(data[column.name])">
            </span>
            <span v-else :id="`popover${index}${i}`" v-bind:class="getColumnClasses(data, column.name)"
              @mouseover="onMouseHover(`popover${index}${i}`, column.name, data[column.name])"
              v-html="data[column.name]">
            </span>
            <div v-if="column.useButton">
              <b-button v-if="data.buttonVisible" variant="outline-primary" size="sm" class="type-button"
                @click="buttonClicked($event, data)">
                <slot name="rowButton"></slot>
              </b-button>
              <span v-else>-</span>
            </div>
            <b-popover v-if="usePopup && popup.name" triggers="hover" :target="`popover${index}${i}`" :content="popup.name"
              :style="popup.style">
            </b-popover>
          </td>
        </tr>
      </table>
    </div>
  </div>

  <div v-else>
    <!-- expanded & fix header table  -->
    <div class="fix-header-table">
      <div style="overflow-x: scroll;">
        <!-- 1行でおさめる場合には class の text-nowrap 設定 -->
        <table style="width: 100%;">
          <thead>
            <div ref="tableColumnHeader">
              <tr v-if="columns">
                <th v-for="(column, i) in columns" :key="i"
                  :style="{ 'min-width': column.width, 'max-width': column.width}">
                  <div class="table-header-cursor" v-if="useSort && !column.useExpand" @click="sort(column)">
                    <span>| {{ $t(column.label) }}</span>
                    <span v-if="currentSort == column.name" v-html="getSortIcon()"></span>
                  </div>
                  <div v-else>
                    <span>| {{ $t(column.label) }}</span>
                  </div>
                </th>
              </tr>
            </div>
          </thead>
          <tbody v-bind:class="{'body-event-none' : !rowSelect}" v-bind:style="{ height: displayMaxTableHeight + 'px' }"
            ref="table_tbody">
            <div v-for="(data, index) in (sortedActivity)" :key="index">
              <tr v-on:click="rowClicked(data)">
                <td v-for="(column, i) in columns" :key="i" v-bind:class="data.bgStyle"
                  :style="{ 'min-width': column.width, 'max-width': column.width }">
                  <div v-if="column.useExpand" class="text-center table-expand-button"
                    @click="expanded($event, data.id, data)">
                    <svg v-if="!opened.includes(data.id)" viewBox="6 6 12 12" class="expand-svg-icon">
                      <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"
                      class="arrow-icon-color" />
                    </svg>
                    <svg v-else viewBox="6 6 12 12" class="expand-svg-icon">
                      <g>
                        <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" 
                        class="arrow-icon-color"/>
                      </g>
                    </svg>
                  </div>
                  <div v-else>
                    <div v-if="column.useInputHtml">
                      <span v-if="column.useResource" :id="`popover${index}${i}`"
                        v-bind:class="getColumnClasses(data, column.name)"
                        @mouseover="onMouseHover(`popover${index}${i}`, column.name, data[column.name])"
                        v-html="$t(data[column.name])">
                      </span>
                      <span v-else :id="`popover${index}${i}`" v-bind:class="getColumnClasses(data, column.name)"
                        @mouseover="onMouseHover(`popover${index}${i}`, column.name, data[column.name])"
                        v-html="data[column.name]">
                      </span>
                    </div>
                    <div v-else>
                      <span v-if="column.useResource" :id="`popover${index}${i}`"
                        v-bind:class="getColumnClasses(data, column.name)"
                        @mouseover="onMouseHover(`popover${index}${i}`, column.name, data[column.name])">
                        {{ $t(data[column.name]) }}
                      </span>
                      <span v-else :id="`popover${index}${i}`" v-bind:class="getColumnClasses(data, column.name)"
                        @mouseover="onMouseHover(`popover${index}${i}`, column.name, data[column.name])">
                        {{ data[column.name] }}
                      </span>
                    </div>
                    <b-popover v-if="usePopup && popup.name" triggers="hover" :target="`popover${index}${i}`" :content="popup.name"
                      :style="popup.style">
                    </b-popover>
                  </div>
                </td>
              </tr>
              <!-- expand した際の領域 -->
              <tr v-if="opened.includes(data.id)">
                <td :colspan="columnLength" class="expand-area">
                  <div>
                    <slot name="expand" v-bind:content="data"></slot>
                  </div>
                </td>
              </tr>
            </div>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
