<div class="container">
  <div class="change-password-container">
    <div class="row row-90">
      <div class="col-12 text-center">
        <label class="label-sub-header">{{ $t('message.header_sub_change_password') }}</label>
        <hr class="hr-line-100">
      </div>

      <div class="col-12 text-center">
        <label class="sub-title-style">{{ $t('message.label_msg_enter_new_password') }}</label>
      </div>

      <div class="col-12">
        <div class="mx-auto" style="max-width: 480px;">
          <!-- TODO v300 => v230 merge マスク対応 -->
          <!-- <label v-html="$t('message.label_password_hint')" class="small"></label> -->
          <b-form-group class="form-group">
            <label class="label-sm">{{ $t('message.label_new_password') }}</label>
            <info-Popover id="password-hint-pop" text="message.label_password_hint" useResource />
            <b-form-input type="password" id="id-new-password" class="w-100" v-model="newPassword" size="sm" />
          </b-form-group>
        </div>
      </div>

      <div class="col-12">
        <div class="mx-auto" style="max-width: 480px;">
          <b-form-group class="form-group">
            <label class="label-sm">{{ $t('message.label_new_password_confirm') }}</label>
            <b-form-input type="password" id="id-confirm-password" class="w-100" size="sm" v-model="newPasswordConfirm"
              @paste.prevent />
          </b-form-group>
        </div>
      </div>

      <div class="col-12 text-center mb-4 mt-3">
        <b-button variant="outline-primary" v-on:click="backToPrevious">
          {{ $t('message.common_button_back') }}
        </b-button>
        <b-button :disabled="!isManadatoryFilled" variant="primary" class="ml-4" v-on:click="executeChangePassword">
          {{ $t("message.button_change_password") }}
        </b-button>
      </div>
    </div>
  </div>

  <processing-view :processing="processing"></processing-view>
</div>