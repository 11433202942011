// 警告メッセージのリソースID
const requiredMsg = 'message.alert_validate_required';
const maxLengthMsg = 'message.alert_validate_max_length';
const patternMsg = 'message.alert_validate_invalid_pattern';
const unmatchedMsg = 'message.alert_validate_enter_unmatch';
const unselectedMsg = 'message.alert_validate_select_required';
const RegionInformationMsg = 'message.alert_validate_select_required_vehicleinfoMsg';

/* **
 * 一般的なフォームの入力内容の検証。
 * @param inputs: 入力内容
 * @param isRequired: 必須項目なら "true", そうでなければ "false"
 * @param maxLength: 入力の最大文字数
 * @param pattern: 入力パターン検証のための正規表現
 * @return: 検証結果を格納したオブジェクト
 */
export const validateCommons = (inputs, isRequired, maxLength, pattern = null) => {
  // 必須項目の入力チェック
  if (isRequired) {
    if (!inputs)
      return { valid: false, msg: requiredMsg, length: '' };
    else {
      const trimed = inputs.trim();
      if (!trimed)
        return { valid: false, msg: requiredMsg, length: '' };
    }
  }
  // 必須でない項目の入力チェック
  if (!inputs) return { valid: null, msg: '', length: '' };

  // 最大文字数のチェック
  const trimed = inputs.trim();
  if (trimed.length > maxLength)
    return { valid: false, msg: maxLengthMsg, length: trimed.length };

  // 入力フォーマットの確認
  if (pattern) {
    if (!pattern.test(trimed)) {
      return { valid: false, msg: patternMsg, length: '' };
    } else {
      /* empty */
    }
  }
  // 適正入力のため正常オブジェクトを返す
  return { valid: null, msg: '', length: '' };
};

/* **
 * 実行画面のみに使用する入力内容の検証。
 * @param inputs: 入力内容
 * @param isRequired: 必須項目なら "true", そうでなければ "false"
 * @param maxLength: 入力の最大文字数
 * @param pattern: 入力パターン検証のための正規表現
 * @param modal: this
 * @return: 検証結果を格納したオブジェクト
 */
export const excuteValidateCommons = (inputs, isRequired, minLength, maxLength, pattern = null) => {
  // 必須項目の入力チェック
  if (isRequired) {
    if (!inputs)
      return { valid: false, msg: requiredMsg, length: '' };
    else {
      const trimed = inputs.trim();
      if (!trimed)
        return { valid: false, msg: requiredMsg, length: '' };
    }
  }
  // 必須でない項目の入力チェック
  if (!inputs) return { valid: null, msg: '', length: '' };

  // 文字数のチェック
  const trimed = inputs.trim();
  if (minLength > trimed.length || maxLength < trimed.length)
    return { valid: false, msg: maxLengthMsg, length: trimed.length };

  // 入力フォーマットの確認
  if (pattern) {
    if (!pattern.test(trimed)) {
      return { valid: false, msg: patternMsg, length: '' };
    } else {
      /* empty */
    }
  }
  // 適正入力のため正常オブジェクトを返す
  return { valid: null, msg: '', length: '' };
};
/* **
 * Eメールフォームの入力内容の検証。
 * 入力の有無、指定パターンとの一致、確認用入力との一致、最大長チェックを行う。
 * @param challenge: 初回の入力内容
 * @param confirm: 確認用の入力内容
 * @param maxLength: 入力の最大文字数
 * @param pattern: 検証の文字列パターン
 * @return: 検証結果を格納したオブジェクト
 */
export const validateEmail = (challenge, confirm, maxLength, pattern) => {
  if (!challenge)
    return [{ valid: false, msg: requiredMsg }, { valid: null, msg: '' }];
  else {
    const trimed = challenge.trim();
    if (!trimed)
      return [{ valid: false, msg: requiredMsg }, { valid: null, msg: '' }];
    else if (trimed.length > maxLength)
      return [{ valid: false, msg: maxLengthMsg, length: trimed.length }, { valid: null, msg: '', length: '' }];
    else if (!pattern.test(trimed))
      return [{ valid: false, msg: patternMsg }, { valid: null, msg: '' }];
    else if (!confirm || trimed !== confirm.trim())
      return [{ valid: null, msg: '' }, { valid: false, msg: unmatchedMsg }];
    else
      return [{ valid: null, msg: '' }, { valid: null, msg: '' }];
  }
};

/* **
 * プルダウン選択の入力内容の検証。
 * @param selected: 選択された内容
 * @return: 検証結果を格納したオブジェクト
 */
export const validateSelection = (selected) => {
  if (typeof selected === 'boolean')
    return { valid: null, msg: '' };
  if (!selected)
    return { valid: false, msg: unselectedMsg };
  else {
    const trimed = selected.trim();
    if (!trimed)
      return { valid: false, msg: unselectedMsg };
    else
      return { valid: null, msg: '' };
  }
};

/* **
 * RegionInformationの検証。 
 * @return: 検証結果を格納したオブジェクト
 */
export const validateRegionInformation = () => {
  return { valid: false, msg: RegionInformationMsg };
};

/* **
 * 一般的なフォームの入力内容の検証。
 * @param inputs: 入力内容
 * @param isRequired: 必須項目なら "true", そうでなければ "false"
 * @param length: 入力の文字数
 * @param pattern: 入力パターン検証のための正規表現
 * @return: 検証結果を格納したオブジェクト
 */
export const validateRequirAndLength = (inputs, isRequired, length, pattern = null) => {
  // 必須項目の入力チェック
  if (isRequired) {
    if (!inputs)
      return { valid: false, msg: requiredMsg };
    else {
      const trimed = inputs.trim();
      if (!trimed)
        return { valid: false, msg: requiredMsg };
    }
  }
  // 必須でない項目の入力チェック
  if (!inputs) return { valid: null, msg: '' };

  // 文字数のチェック
  const trimed = inputs.trim();
  if (trimed.length !== length)
    return { valid: false, msg: maxLengthMsg, length: trimed.length };

  // 入力フォーマットの確認
  if (pattern) {
    if (!pattern.test(trimed)) {
      return { valid: false, msg: patternMsg };
    } else {
      /* empty */
    }
  }
  // 適正入力のため正常オブジェクトを返す
  return { valid: null, msg: '' };
};

export default {
  validateCommons,
  validateEmail,
  validateSelection,
  validateRegionInformation,
  validateRequirAndLength,
  excuteValidateCommons
};