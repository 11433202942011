<li>
  <div v-if="showHeadElements"
    :class="itemClassName"
    @mouseenter="mouseEnterAndlLeave($event, true)"
    @mouseleave="mouseEnterAndlLeave($event, false)"
    @click="nodeSelected($event)">

    <div v-if="!item.hasData"
      class="item-height-div"
      :class="{'item-select-style' : item.isSelected, 'item-hover-style' : isHover}">
      <!-- TreeView左margin領域 -->
      <span class="item-height-span" v-bind:style="margin"></span>
      <span v-if="!isLast" class="ml-1 item-height-span" @click="expanded($event)">
        <svg v-if="!isOpen" viewBox="0 0 24 24" class="icon-size">
          <path d="M0 0h24v24H0z" fill="none" />
          <path class="icon-color" d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
          <!-- TODO v300 => v230 merge マスク対応 -->
          <!-- <path :class="[item.isSelected ? 'icon-select-color' : '' , 'icon-color']" d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" /> -->
        </svg>
        <svg v-else viewBox="0 0 24 24" class="icon-size">
          <path d="M0 0h24v24H0z" fill="none" />
          <path class="icon-color" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
          <!-- TODO v300 => v230 merge マスク対応 -->
          <!-- <path :class="[item.isSelected ? 'icon-select-color' : '' , 'icon-color']" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" /> -->
        </svg>
      </span>
      <!-- TreeView値領域 -->
      <span class="item-height-span" :class="{'end-item-margin-left' : isLast }">
        <span v-if="!item.useResource">
          <span>{{ item.name }}</span>
          <div v-if="item.hasExtraColumn" class="extra-column-margin">
            <span v-bind:style="extraMargin">{{ item.extraColumns.name }}</span>
          </div>
        </span>
        <span v-else>
          <span>{{ $t(item.name) }}</span>
          <div v-if="item.hasExtraColumn" class="extra-column-margin">
            <span v-bind:style="extraMargin">{{ $t(item.extraColumns.name) }}</span>
          </div>
        </span>
      </span>
    </div>

    <div class="text-left item-loading-style" v-if="isloading">
      <span v-bind:style="margin"></span>
      <b-spinner class="ml-2" variant="primary"></b-spinner>
      <strong>Loading...</strong>
    </div>
  </div>

  <ul v-if="!isLast" v-show="isOpen" class="node-treeview-selectable">
    <tree-item v-for="(child, index) in item.children" :key="index" :item="child"
      :showHeadElements="true"
      :level="level + 1"
      :class="{ 'item-cursor': !child.hasData, 'item-cursor-none': child.hasData }"
      @expanded-item="$emit('expanded-item', $event)"
      @node-Selected="$emit('node-Selected', $event)">
    </tree-item>
  </ul>
</li>