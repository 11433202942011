<div class="container">
  <div v-if="isTestMode" class="row text-center">
    <div class="col-12">
      <label class="sub-title-style alert-msg-color">{{ $t('message.label_title_test_mode') }}</label>
    </div>
  </div>

  <div class="row">
    <!-- リプロ実行失敗画面 -->
    <div v-if="reproResult.status === 'executing-error'" class="col-12 text-center">
      <div class="result-container">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <label class="mt-4 rpr-result-style">
            {{ $t('message.header_repro_result_error') }}
          </label>
        </div>
        <hr class="hr-ng-line-95">
        <!-- エラー詳細 -->
        <div>
          <div class="col-12 text-left row mt-4">
            <div class="col-3 text-right">{{ $t('message.label_repro_result_reprogram') }}:</div>
            <div class="col-9 ng-color">NG</div>
          </div>
          <div class="col-12 text-left row mt-1">
            <div class="col-3 text-right">{{ $t('message.label_repro_error_code') }}:</div>
            <div class="col-9">{{ reproResult.errorCode }}</div>
          </div>
          <div class="col-12 text-left row mt-1">
            <div class="col-3 text-right">{{ $t('message.label_repro_error_cause') }}:</div>
            <div class="col-9" v-show="!reproResult.isSeqDllError" v-html="reproResult.errorMessage"></div>
            <div class="col-9" v-show="reproResult.isSeqDllError && reproResult.cloudConnectorMsg"
              v-html="reproResult.cloudConnectorMsg"></div>
          </div>
          <div class="col-12 text-left row mt-1" v-show="reproResult.isSeqDllError && reproResult.nextAction">
            <div class="col-3 text-right">{{ $t('message.label_repro_error_action') }}:</div>
            <div class="col-9" v-html="reproResult.nextAction"></div>
          </div>
        </div>

        <!-- 再リプロボタン・診断画面に戻るボタン -->
        <div class="col-12 text-center mt-4">
          <b-button variant="primary" v-on:click="executeAgain()" class="mr-2 mb-3">
            {{ $t('message.button_repro_execute_again') }}</b-button>
          <b-button variant="primary" v-on:click="backErrorToHome()" class="ml-2 mb-3">
            {{ $t('message.button_repro_back_to_diagnosis') }}</b-button>
        </div>
      </div>
    </div>

    <!-- リプロ完了画面（失敗） -->
    <div v-else-if="reproResult.status === 'finished-error'" class="col-12 text-center">
      <div class="result-container">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <label class="mt-4 rpr-result-style">
            {{ $t('message.header_repro_result_error') }}
          </label>
        </div>
        <hr class="hr-ng-line-95">
        <!-- エラー詳細 -->
        <div>
          <div class="col-12 text-left row">
            <div class="col-3 text-right">{{ $t('message.label_repro_result_reprogram') }}:</div>
            <div class="col-9 ng-color">NG</div>
          </div>
          <div class="col-12 text-left row mt-1">
            <div class="col-3 text-right">{{ $t('message.label_repro_error_code') }}:</div>
            <div class="col-9" v-html="reproResult.errorCode"></div>
          </div>
          <div class="col-12 text-left row mt-1">
            <div class="col-3 text-right">{{ $t('message.label_repro_error_cause') }}:</div>
            <div class="col-9" v-show="!reproResult.isSeqDllError" v-html="reproResult.errorMessage"></div>
            <div class="col-9" v-show="reproResult.isSeqDllError && reproResult.cloudConnectorMsg"
              v-html="reproResult.cloudConnectorMsg"></div>
          </div>
          <div class="col-12 text-left row mt-1" v-show="reproResult.isSeqDllError && reproResult.nextAction">
            <div class="col-3 text-right">{{ $t('message.label_repro_error_action') }}:</div>
            <div class="col-9">
              <div v-html="reproResult.nextAction"></div>
              <div v-if="isShowSpecialAction && !useCcuMsg" v-html="$t('message.message_post_repro_failed_action')"></div>
              <div v-if="isShowSpecialAction && useCcuMsg" v-html="$t('message.message_post_repro_failed_action_swid')"></div>
            </div>
          </div>
        </div>
        <!-- 再リプロボタン -->
        <!--<div class="col-12 text-center mt-4">
        <b-button variant="primary" v-on:click="executeAgain()">
          {{ $t('message.button_repro_execute_again') }}</b-button>
      </div>-->
        <!-- 診断画面に戻るボタン -->
        <div class="col-12 text-center mt-4">
          <b-button variant="primary" v-on:click="backErrorToHome()" class="ml-2 mb-3">
            {{ $t('message.button_repro_back_to_diagnosis') }}
          </b-button>
        </div>
      </div>
    </div>

    <!-- リプロ完了画面（成功） -->
    <div v-else class="col-12 text-center w-100">
      <div class="result-container">
        <div class="col-12 text-left">
          <b-button variant="primary" v-on:click="displayPrintPreview()" class="mt-3">
            {{ $t('message.button_repro_print') }}
          </b-button>
        </div>
        <div class="col-12 w-100">
          <label class="mt-4 rpr-result-style">
            {{ $t('message.header_repro_result_process_completed') }}
          </label>
        </div>
        <div class="col-12 text-right">
          <label>{{ reproCompletedDate }}</label>
        </div>
        <div class="col-12 mt-3 text-left tbl-line-break-style">
          <b-table striped responsive :items="reproTargetInfo" :fields="fieldsConfig"></b-table>
        </div>
        <div class="col-12 mt-4">
          <label class="rpr-msg-alert-color" v-html="getAlertMsgHtml()"></label>
        </div>
        <!-- 診断画面に戻るボタン -->
        <div class="col-12 text-center mt-3">
          <b-button variant="primary" v-on:click="backToHome()" class="mb-3">
            {{ $t('message.button_repro_back_to_diagnosis') }}</b-button>
        </div>
      </div>
    </div>

    <div v-if="needToDisplayDownloadTime()" class="col-12 text-right w-100 mt-4">
      <div class="text-right">{{ $t('message.label_repro_execute_dl_taken_time') }}: {{ convertFileDownloadTime() }}
      </div>
      <div class="text-right">{{ $t('message.label_repro_execute_dl_speed') }}: {{ convertFileDownloadSpeed() }}</div>
    </div>
  </div>

  <processing-view :processing="processing"></processing-view>
</div>