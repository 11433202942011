/* eslint-disable max-lines */

import api from '../../common/api';
const Error = require('../../common/nodejs/errors');
import config from '../../share/config';
import handler from '../../share/util/apiResultValidator';
import util from '../../share/util/utils';
import logWrapper from '../../share/logWrapper';
const vehicleTestMode = require('../../data/testMode/vehicleInfo.json');

export default {
  namespaced: true,

  state: {
    // 必須項目
    status: null,
    error: null,
    watcher: null,
    // 車種情報
    progress: null,
    vehicleInfo: null,
    voltage: null,
    // 通信処理フェーズ
    phase: null,
    completed: false,
    // v221 より、電圧過不足・RxSWIN 更新処理に関連するエラーは err_code_list で返される
    // ポーリング中、リスト内のエラーコードに応じた警告ダイアログを表示する必要があるが、エラーは
    // 積み上げ方式なので、すでに利用者に対して表示したエラーの内容は Frontend 側で覚えておき
    // 新規のエラーが積み上げられた時点で、その新規エラーだけ警告ダイアログを表示。
    // エラー群を文字列で保持するのは、配列の要素変更をVuexのイベントで検知漏れしてしまうことを防止するため
    warnings: '',
    stackedWarnings: '',
    // systems API を実行したかの有無
    isExecutSystemsAPI: null,
    deleteResultDtcs: null,
    systemCount: null
  },

  mutations: {
    // 必須項目
    setStatus(state, payload) {
      state.error = payload.error;
      state.status = payload.status;
    },
    setWatcher(state, payload) {
      state.watcher = payload;
    },
    // 車種情報
    setProgress(state, payload) {
      state.progress = payload;
    },
    setVehicle(state, payload) {
      state.vehicleInfo = payload;
    },
    setvoltage(state, payload) {
      state.voltage = payload;
    },
    setPhase(state, payload) {
      state.phase = payload;
    },
    setWarnings(state, payload) {
      state.warnings = payload;
    },
    setStackedWarnings(state, payload) {
      state.stackedWarnings = payload;
    },
    setIsExecutSystemsAPI(state, payload) {
      state.isExecutSystemsAPI = payload;
    },
    // DTCコード削除
    setResultDtc(state, payload) {
      state.deleteResultDtcs = payload;
    },
    setSystemCount(state, payload) {
      state.systemCount = payload;
    },
  },

  actions: {
    /* **
    * 車両情報の更新処理(ポーリングを実施して値が正常に更新されたかチェックする)
    * @param obj.this: this を指定
    * @param obj.netAppId: 接続した netAppId 
    * @param obj.endPoint: 車種情報全体 or 電圧
    * @param obj.unitId: 対象ユニットID
    */
    /* eslint-disable complexity */
    async updateVehicleInfo(context, obj) {

      // テストモードの車両情報取得
      if (context.rootState.netAppConnector.isTestMode) {
        await context.dispatch('testModeUpdateVehicleInfo', obj);
        return;
      }

      // 実車モードの車両情報取得
      context.commit('setStatus', util.createResultInfo(config.POLLING_INITIALIZED));

      // SystemsAPI実行の有無情報を初期化
      context.commit('setIsExecutSystemsAPI', null);
      // 進捗状態を初期化
      context.commit('setProgress', 0);
      context.commit('setPhase', config.COMMUNICATION_UI_VEHICLE_OBD);

      let watcher = null;
      let netAppsRequest = '/netApps/' + obj.netAppId;
      if (obj.unitId) {
        netAppsRequest = netAppsRequest + '/' + obj.unitId;
      }
      if (obj.endPoint) {
        netAppsRequest = netAppsRequest + obj.endPoint;
      }

      // exec_type が付与されていない場合は all で呼び出しを実施する。 
      let param = { exec_type: config.INQUIRY_TYPE_ALL };
      if (obj.execType) {
        param = { exec_type: obj.execType };
      }

      // 車種情報(電圧)更新のリクエスト
      const response = await api.postCall(config.REPRO, netAppsRequest, param);
      await util.sleep(config.WAIT_DB_EVENT_TIME);
      const isSuccess = handler.validate(
        handler.validateTypes.all,
        response, obj.this, null, null,
        (result) => {
          // 処理が失敗してる場合
          context.commit('setStatus',
            util.createResultInfo(config.POLLING_FAILURE, result.message, result.behaviorType, result));
        }, null, false);

      // 成功しない場合は処理を終了
      if (!isSuccess) {
        return;
      }

      // 車種情報の取得待ち 
      let currentRetry = 0;
      const retryCount = Number(process.env.VUE_APP_VEHICLE_RETRY_COUNT);

      watcher = obj.this.$startInterval(async () => {
        try {
          const response = await api.getCall(config.REPRO, `/pairings/${obj.netAppId}`);
          handler.validate(handler.validateTypes.all, response, obj.this, null, async () => {
            try {
              // 処理が成功したときのコールバック処理
              const watchResponse = response.data;
              // 取得した情報のVIN番号が異なる場合 
              if (obj.execType && context.rootState.cache.vehicleInfo.vehicle.vin.vin !== watchResponse.vehicle.vin.vin) {
                const result = { behaviorType: handler.behaviorTypes.other, causeType: handler.causeTypes.differentVinError, message: 'message.message_change_vehicle_info_error' };
                context.commit('setStatus',
                  util.createResultInfo(config.POLLING_FAILURE, 'message.message_change_vehicle_info_error', result.behaviorType, result));
                return;
              }
              if (handleErrCodeList(context, obj, watchResponse.err_code_list || [])) {
                obj.this.$stopInterval(watcher);
                return;
              }

              // 進捗状態を設定
              const prg = watchResponse.net_app_checking_progress;
              context.commit('setProgress', (prg > 95 ? 95 : prg));
              // 車両情報取得の詳細情報判定
              const ph = watchResponse.net_app_checking_status;
              let currentPhase;
              switch (ph) {
                case config.COMMUNICATION_API_VIN_COMPLETED: {
                  currentPhase = config.COMMUNICATION_UI_VIN_COMPLETED;
                  obj.this.$pauseInterval(watcher);
                  // vin_completed フェーズならシステムチェック開始
                  const isSystemsSuccess = await context.dispatch('runSystemApi', obj);
                  if (!isSystemsSuccess) {
                    // systemsAPIで処理が失敗してる場合は処理終了
                    obj.this.$stopInterval(watcher);
                    return;
                  }
                  obj.this.$resumeInterval(watcher);
                  break;
                }
                case config.COMMUNICATION_API_VIN_FAILED:
                case config.COMMUNICATION_API_CHASSIS_COMPLETED: {
                  obj.this.$pauseInterval(watcher);
                  // VIN情報の再取得
                  const responseVin = await api.getCall(config.REPRO, '/netApps/' + obj.netAppId);
                  handler.validate(handler.validateTypes.all, responseVin, obj.this, null, () => {
                    try {
                      // 処理が成功したときのコールバック処理, 車種情報を設定
                      context.commit('setVehicle', responseVin.data);
                      currentPhase = config.COMMUNICATION_UI_VIN_REQUIRED;
                      context.commit('setPhase', currentPhase);
                      obj.this.$resumeInterval(watcher);
                    } catch (err) {
                      // 予期しない例外が発生
                      obj.this.$stopInterval(watcher);
                      context.commit('setStatus',
                        util.createResultInfo(config.POLLING_FAILURE,
                          obj.this.$t(api.getErrorMessageForCode(Error.CLOUD_CONNECTOR_PAIRING_RSP_NOT_RECEIVED)),
                          handler.behaviorTypes.other, err));
                    }
                  }, (result) => {
                    obj.this.$stopInterval(watcher);
                    context.commit('setStatus', util.createResultInfo(config.POLLING_FAILURE, result.message, result.behaviorType, result));
                    return;
                  }, null, false);
                  break;
                }
                case config.COMMUNICATION_API_READ_VBAT:
                case config.COMMUNICATION_API_READ_VIN:
                  currentPhase = config.COMMUNICATION_UI_VEHICLE_OBD;
                  break;
                case config.COMMUNICATION_API_READ_VEHICLE_TIMESTAMP:
                case config.COMMUNICATION_API_INSTALL_ECU:
                case config.COMMUNICATION_API_CHECK_CGW_SUPPORT:
                case config.COMMUNICATION_API_READ_RxSWIN:
                  currentPhase = config.COMMUNICATION_UI_VEHICLE_CGW;
                  break;
                case config.COMMUNICATION_API_READ_SECURITY_ACCESS:
                case config.COMMUNICATION_API_ALL_TARGET_INQUIRY:
                  currentPhase = config.COMMUNICATION_UI_VEHICLE_SYSTEMS;
                  break;
                case config.COMMUNICATION_API_UPDATING_RxSWIN:
                  currentPhase = config.COMMUNICATION_UI_RXSWIN_UPDATED;
                  break;
                case config.COMMUNICATION_API_DIAG_INFO:
                  currentPhase = config.COMMUNICATION_UI_GET_DIAG_CODE;
                  break;
                case config.COMMUNICATION_API_COMPLETED:
                  // --------------------------------------------
                  currentPhase = config.COMMUNICATION_UI_VEHICLE_SYSTEMS;
                  // TODO v300 => v230 merge マスク対応 ----------
                  // currentPhase = config.COMMUNICATION_UI_COMMUNICATION_COMPLETED;
                  // --------------------------------------------
                  break;
                default:
                  currentPhase = config.COMMUNICATION_UI_VEHICLE_CGW;
                  break;
              }
              context.commit('setPhase', currentPhase);

              // 情報の更新が完了
              const isCompleted = watchResponse.is_net_app_checking_completed;
              if (isCompleted) {
                // RxSWIN更新が成功していない場合初期値のnullのままとする。
                let rxswinUpdateMsg = null;
                // 完了している場合はタイマーを停止する 
                obj.this.$stopInterval(watcher);
                // 進捗状態を設定
                const prg = watchResponse.net_app_checking_progress;
                context.commit('setProgress', (prg > 95 ? 95 : prg));

                // 応答内容検証
                if (watchResponse.err_code !== 0 || watchResponse.err_msg) {
                  let message = obj.this.$t(api.getErrorMessageForCode(watchResponse.err_code));
                  // エラーコードからメッセージが取得できない場合は取得したエラーメッセージをそのまま表示する 
                  if (!message) { message = watchResponse.err_msg; }
                  // エラー返却用の情報を作成
                  const result = {
                    behaviorType: handler.behaviorTypes.other,
                    causeType: handler.causeTypes.other,
                    message: watchResponse.err_msg,
                    consoleCode: watchResponse.err_code
                  };
                  context.commit('setStatus', util.createResultInfo(config.POLLING_FAILURE, message, handler.behaviorTypes.other, result, watchResponse));
                  return;
                }
                // RxSWIN更新が実施されているかつ Start 画面から本関数が読み出されている場合はメッセージIDを代入する。
                else if (obj.options && obj.options.isRxswinUpdateFlow && watchResponse.is_rxswin_updated) {
                  rxswinUpdateMsg = 'message.message_home_success_update_rxswin';
                }

                // 車種情報(電圧)更新のリクエスト
                const responseVehicle = await api.getCall(config.REPRO, '/netApps/' + obj.netAppId);
                handler.validate(handler.validateTypes.all, responseVehicle, obj.this, null, () => {
                  try {
                    // 処理が成功したときのコールバック処理, 車種情報を設定
                    const vehicleInfo = responseVehicle.data;
                    context.commit('setVehicle', vehicleInfo);
                    // 電圧情報を設定
                    if (vehicleInfo.vehicle && vehicleInfo.vehicle.battery_voltage) {
                      if (vehicleInfo.vehicle.battery_voltage.is_valid) {
                        context.commit('setvoltage', vehicleInfo.vehicle.battery_voltage.voltage);
                      } else {
                        context.commit('setvoltage', null); // 電圧情報を初期化
                      }
                    } else {
                      context.commit('setvoltage', null);   // 電圧情報を初期化
                    }
                    context.commit('setStatus', util.createResultInfo(config.POLLING_COMPLETED));
                  } catch (err) {
                    // 予期しない例外が発生
                    obj.this.$stopInterval(watcher);
                    context.commit('setStatus',
                      util.createResultInfo(config.POLLING_FAILURE,
                        obj.this.$t(api.getErrorMessageForCode(Error.CLOUD_CONNECTOR_PAIRING_RSP_NOT_RECEIVED)),
                        handler.behaviorTypes.other, err));
                  }
                }, (result) => {
                  // 処理が失敗してる場合のコールバック処理
                  context.commit('setStatus', util.createResultInfo(config.POLLING_FAILURE, result.message, result.behaviorType, result));
                }, rxswinUpdateMsg, false);
                return;
              }

              // リトライ上限超過
              if (++currentRetry === retryCount) {
                obj.this.$stopInterval(watcher);
                context.commit('setStatus',
                  util.createResultInfo(config.POLLING_FAILURE,
                    obj.this.$t(api.getErrorMessageForCode(Error.CLOUD_CONNECTOR_PAIRING_RSP_NOT_RECEIVED)),
                    handler.behaviorTypes.other));
                return;
              }
            } catch (err) {
              // 予期しない例外が発生
              obj.this.$stopInterval(watcher);
              context.commit('setStatus',
                util.createResultInfo(config.POLLING_FAILURE,
                  obj.this.$t(api.getErrorMessageForCode(Error.CLOUD_CONNECTOR_PAIRING_RSP_NOT_RECEIVED)),
                  handler.behaviorTypes.other, err));
            }
          }, (result) => {
            // 処理が失敗してる場合
            obj.this.$stopInterval(watcher);
            context.commit('setStatus',
              util.createResultInfo(config.POLLING_FAILURE, result.message, result.behaviorType, result));
          }, null, false);
        } catch (err) {
          // 予期しない例外が発生
          //console.log('Unexpected Error has occured @vehicle#updateVehicleInfo: ', err);
          obj.this.$stopInterval(watcher);
          context.commit('setStatus',
            util.createResultInfo(config.POLLING_FAILURE,
              obj.this.$t(api.getErrorMessageForCode(Error.CLOUD_CONNECTOR_PAIRING_RSP_NOT_RECEIVED)),
              handler.behaviorTypes.other, err));
        }
      }, Number(process.env.VUE_APP_VEHICLE_RETRY_INTERVAL), "updateVehicleInfo " + netAppsRequest);

      // ポーリングの watcher ID を設定
      context.commit('setWatcher', watcher);
      // 進捗状態を設定(0%)
      context.commit('setProgress', 0);
      // ポーリングの開始ステータスを設定
      context.commit('setStatus', util.createResultInfo(config.POLLING_RUNNING));
    },

    /* **
    * 【テストモード】車両情報の更新処理
    * @param obj.this: this を指定
    * @param obj.netAppId: 接続した netAppId 
    * @param obj.endPoint: 車種情報全体 or 電圧
    * @param obj.unitId: 対象ユニットID
    */
    async testModeUpdateVehicleInfo(context, obj) {
      context.commit('setStatus', util.createResultInfo(config.POLLING_INITIALIZED));
      // SystemsAPI実行の有無情報を初期化
      context.commit('setIsExecutSystemsAPI', null);
      // 進捗状態を初期化
      context.commit('setProgress', 0);
      context.commit('setPhase', config.COMMUNICATION_UI_VEHICLE_OBD);

      let watcher = null;
      watcher = obj.this.$startInterval(async () => {
        // 進捗状態を設定
        const inquiryProgress = context.state.progress + (obj.endPoint ? 50 : 20);
        context.commit('setProgress', inquiryProgress);

        // テストモードの進捗率を仮設定
        if (inquiryProgress <= 30) {
          context.commit('setPhase', config.COMMUNICATION_UI_VEHICLE_OBD);
        } else if (inquiryProgress <= 70) {
          context.commit('setPhase', config.COMMUNICATION_UI_VEHICLE_CGW);
        } else {
          context.commit('setPhase', config.COMMUNICATION_UI_VEHICLE_SYSTEMS);
        }

        // 情報の更新が完了
        const isCompleted = inquiryProgress === 100 ? true : false;
        if (isCompleted) {
          // 完了している場合はタイマーを停止する 
          obj.this.$stopInterval(watcher);

          // 進捗状態を設定
          context.commit('setProgress', inquiryProgress);

          // 処理が成功したときのコールバック処理
          // 車種情報を設定 
          const vehicleInfo = vehicleTestMode;
          vehicleInfo.id = obj.netAppId;
          context.commit('setVehicle', vehicleInfo);

          // 電圧情報を設定
          if (vehicleInfo.vehicle && vehicleInfo.vehicle.battery_voltage) {
            if (vehicleInfo.vehicle.battery_voltage.is_valid) {
              context.commit('setvoltage', vehicleInfo.vehicle.battery_voltage.voltage);
            }
            else {
              // 電圧情報を初期化 
              context.commit('setvoltage', null);
            }
          }
          else {
            // 電圧情報を初期化 
            context.commit('setvoltage', null);
          }
          context.commit('setStatus', util.createResultInfo(config.POLLING_COMPLETED));
        }
      }, Number(process.env.VUE_APP_VEHICLE_RETRY_INTERVAL), "updateVehicleInfo Test");
      // ポーリングの watcher ID を設定
      context.commit('setWatcher', watcher);
      // 進捗状態を設定(0%)
      context.commit('setProgress', 0);
      // ポーリングの開始ステータスを設定
      context.commit('setStatus', util.createResultInfo(config.POLLING_RUNNING));
    },

    /* **
    * メモリクリアの更新処理(ポーリングを実施して値が正常に更新されたかチェックする)
    * @param obj.this: this を指定
    * @param obj.netAppId: 接続した netAppId 
    * @param obj.endPoint: メモリクリア
    */
    async deleteMemoryClear(context, obj) {
      const netAppId = obj.netAppId;
      context.commit('setStatus', util.createResultInfo(config.POLLING_INITIALIZED));

      // 進捗状態を初期化
      context.commit('setProgress', 0);
      // メモリクリアのカウントの初期化
      context.commit('setSystemCount', { completedConut: null, allConut: null });
      // メモリクリア結果の初期化
      context.commit('setResultDtc', null);


      let watcher = null;
      // メモリクリアのリクエスト
      const endPoint = '/netApps/' + obj.netAppId + '/' + 'vehicle/clearDtc';
      const response = await api.postCall(config.REPRO, endPoint);
      await util.sleep(config.WAIT_DB_EVENT_TIME);
      const isSuccess = handler.validate(
        handler.validateTypes.all,
        response, obj.this, null, null,
        (result) => {
          // 処理が失敗してる場合
          context.commit('setStatus',
            util.createResultInfo(config.POLLING_FAILURE, result.message, result.behaviorType, result));
        }, null, false);

      // 成功しない場合は処理を終了
      if (!isSuccess) {
        return;
      }

      // メモリクリア情報の取得待ち 
      let currentRetry = 0;
      const retryCount = Number(process.env.VUE_APP_VEHICLE_RETRY_COUNT);
      watcher = obj.this.$startInterval(async () => {
        try {
          const response = await api.getCall(config.REPRO, `/pairings/${obj.netAppId}`);
          handler.validate(
            handler.validateTypes.all,
            response, obj.this, null,
            async () => {
              // 処理が成功したときのコールバック処理
              const watchResponse = response.data;

              // 進捗状態を設定
              context.commit('setProgress', watchResponse.clear_dtc_progress);
              // メモリクリアの詳細情報判定
              context.commit('setSystemCount', { completedConut: watchResponse.clear_dtc_completed_system_count, allConut: watchResponse.clear_dtc_all_system_count });

              // 情報の更新が完了
              const isCompleted = watchResponse.is_clear_dtc_completed;
              if (isCompleted) {
                // 完了している場合はタイマーを停止する 
                obj.this.$stopInterval(watcher);
                // 進捗状態を設定
                context.commit('setProgress', watchResponse.clear_dtc_progress);
                // 応答内容検証
                if (watchResponse.err_code !== 0 || watchResponse.err_msg) {
                  let message = obj.this.$t(api.getErrorMessageForCode(watchResponse.err_code));
                  // エラーコードからメッセージが取得できない場合は取得したエラーメッセージをそのまま表示する 
                  if (!message) {
                    message = watchResponse.err_msg;
                  }
                  // エラー返却用の情報を作成
                  const result = {
                    behaviorType: handler.behaviorTypes.other,
                    causeType: handler.causeTypes.other,
                    message: watchResponse.err_msg,
                    consoleCode: watchResponse.err_code
                  };
                  context.commit('setStatus',
                    util.createResultInfo(config.POLLING_FAILURE, message, handler.behaviorTypes.other, result, watchResponse));
                  return;
                }

                // メモリクリア情報の取得待ち 
                const endPoint = '/diagDtc/clearDtcResult/' + obj.netAppId;
                const responseDtcClear = await api.getCall(config.REPRO, endPoint);
                handler.validate(
                  handler.validateTypes.all,
                  responseDtcClear, obj.this, null,
                  () => {
                    context.commit('setResultDtc', responseDtcClear.data);
                    context.commit('setStatus', util.createResultInfo(config.POLLING_COMPLETED));
                    return;
                  },
                  (result) => {
                    // 処理が失敗してる場合のコールバック処理
                    context.commit('setStatus',
                      util.createResultInfo(config.POLLING_FAILURE, result.message, result.behaviorType, result));
                  });
                return;
              }

              // リトライ上限超過
              if (++currentRetry === retryCount) {
                obj.this.$stopInterval(watcher);
                context.commit('setStatus',
                  util.createResultInfo(config.POLLING_FAILURE,
                    obj.this.$t(api.getErrorMessageForCode(Error.CLOUD_CONNECTOR_PAIRING_RSP_NOT_RECEIVED)),
                    handler.behaviorTypes.other));
                return;
              }
            },
            (result) => {
              // 処理が失敗してる場合
              obj.this.$stopInterval(watcher);
              context.commit('setStatus',
                util.createResultInfo(config.POLLING_FAILURE, result.message, result.behaviorType, result));
            }, null, false);
        } catch (err) {
          // 予期しない例外が発生
          //console.log('Unexpected Error has occured @vehicle#updateVehicleInfo: ', err);
          obj.this.$stopInterval(watcher);
          context.commit('setStatus',
            util.createResultInfo(config.POLLING_FAILURE,
              obj.this.$t(api.getErrorMessageForCode(Error.CLOUD_CONNECTOR_PAIRING_RSP_NOT_RECEIVED)),
              handler.behaviorTypes.other, err));
        }
      }, Number(process.env.VUE_APP_VEHICLE_RETRY_INTERVAL), "deleteMemoryClear " + netAppId);


      // ポーリングの watcher ID を設定
      context.commit('setWatcher', watcher);
      // 進捗状態を設定(0%)
      context.commit('setProgress', 0);
      // ポーリングの開始ステータスを設定
      context.commit('setStatus', util.createResultInfo(config.POLLING_RUNNING));
    },

    /* **
    * VIN(車両番号)情報の更新処理(ポーリング無し)
    * @param this: this を指定
    * @param netAppId: 接続した netAppId 
    * @param params: VIN情報更新パラメータ 
    */
    async updateVin(context, obj) {

      let val = util.createResultInfo(config.POLLING_INITIALIZED);
      try {
        // VIN(車両番号)情報の更新のリクエスト
        const response = await api.postCall(config.REPRO, "/netApps/" + obj.netAppId + "/vehicle", obj.params);
        const isSuccess = handler.validate(
          handler.validateTypes.all,
          response, obj.this, null, null,
          (result) => {
            // 処理が失敗してる場合
            val = util.createResultInfo(config.POLLING_FAILURE, result.message, result.behaviorType, result);
          }, null, false);

        // 成功しない場合は処理を終了
        if (!isSuccess) {
          return val;
        }

        // 車種情報更新のリクエスト
        const responseVehicle = await api.getCall(config.REPRO, '/netApps/' + obj.netAppId);
        handler.validate(
          handler.validateTypes.all,
          responseVehicle, obj.this, null,
          () => {
            // 処理が成功したときのコールバック処理
            // 内部の車両情報を更新 
            context.commit('setVehicle', responseVehicle.data);
            val = util.createResultInfo(config.POLLING_COMPLETED);
          },
          (result) => {
            // 処理が失敗してる場合のコールバック処理
            val = util.createResultInfo(config.POLLING_FAILURE, result.message, result.behaviorType, result);
          }, null, false);
        return val;
      } catch (err) {
        // 予期しない例外が発生
        //console.log('Unexpected Error has occured @vehicle#updateVehicleInfo: ', err);
        val = util.createResultInfo(config.POLLING_FAILURE,
          obj.this.$t(api.getErrorMessageForCode(Error.CLOUD_CONNECTOR_PAIRING_RSP_NOT_RECEIVED)),
          handler.behaviorTypes.other, err);
        return val;
      }
    },

    // 電圧のみの更新
    async updateVoltage(context, obj) {
      context.commit('setStatus', util.createResultInfo(config.POLLING_INITIALIZED));
      let watcher = null;
      const response = await api.postCall(config.REPRO, '/netApps/' + obj.netAppId + '/vehicle/battery');
      await util.sleep(config.WAIT_DB_EVENT_TIME);
      const isValidResponse = handler.validate(handler.validateTypes.all, response, obj.instance, null, null, (result) => {
        context.commit('setStatus',
          util.createResultInfo(config.POLLING_FAILURE, result.message, result.behaviorType, result));
      }, null, false);

      // 成功しない場合は処理を終了
      if (!isValidResponse) return;

      let currentRetry = 0;
      const retryCount = Number(process.env.VUE_APP_VEHICLE_RETRY_COUNT);
      watcher = obj.instance.$startInterval(async () => {
        try {
          const res = await api.getCall(config.REPRO, `/pairings/${obj.netAppId}`);
          handler.validate(handler.validateTypes.all, res, obj.instance, null, async () => {
            try {
              // battery/rxswin 警告があればポーリング中止
              // 成功ステータスが err_code_list で返却された場合は除外
              const warnings = (res.data.err_code_list || []).filter(err => err !== 0 && err !== '0' && err !== null);
              const tmp = context.state.stackedWarnings;
              if (warnings.length > 0) {
                // 警告情報が取得出来た場合でも、is_net_app_checking_completedがtrueになるまではポーリングし続ける
                const isCompleted = res.data.is_net_app_checking_completed;
                if (!isCompleted) {
                  logWrapper.log('is_net_app_checking_completed: isCompleted ', isCompleted);
                  return;
                }
                // すでにスタック済みの err_code_list のコードは state に設定しない
                // つまり新規の err_code_list の内容だけを呼び出し側の watch に通知させる
                const checkedErrs = tmp ? tmp.split(',') : [];
                const newErrs = [];
                for (const w of warnings) {
                  if (!checkedErrs.includes(String(w))) newErrs.push(String(w));
                }
                if (newErrs.length > 0) {
                  const sumErrs = tmp ? (tmp + ',' + newErrs.join(',')) : newErrs.join(',');
                  // 検知済みエラーコードの更新
                  context.commit('setStackedWarnings', sumErrs);
                  // 新規エラーコードだけを state に設定
                  context.commit('setWarnings', newErrs.join(','));
                }
                obj.instance.$stopInterval(watcher);
                return;
              }
              // 各種状態を設定
              // 進捗ゲージは表示不要であるため、Progress, Phase には情報をセットしない
              // context.commit('setProgress', res.data.net_app_checking_progress);
              // context.commit('setPhase', getCurrentPhase(res.data.net_app_checking_status));
              // ポーリング完了判定
              const isCompleted = res.data.is_net_app_checking_completed;
              if (isCompleted) {
                // ポーリング停止
                obj.instance.$stopInterval(watcher);
                // エラーありなら電圧取得しない
                if (res.data.err_code !== 0 || res.data.err_msg) {
                  const tmp = obj.instance.$t(api.getErrorMessageForCode(res.data.err_code));
                  const msg = tmp || res.data.err_msg;
                  const result = {
                    behaviorType: handler.behaviorTypes.other,
                    causeType: handler.causeTypes.other,
                    message: res.data.err_msg,
                    consoleCode: res.data.err_code
                  };
                  context.commit('setStatus',
                    util.createResultInfo(config.POLLING_FAILURE, msg, handler.behaviorTypes.other, result, res.data));
                  return;
                }
                // 電圧取得
                const vlt = await api.getCall(config.REPRO, '/netApps/' + obj.netAppId);
                handler.validate(handler.validateTypes.all, vlt, obj.instance, null, () => {
                  context.commit('setVehicle', vlt.data);
                  // 電圧情報を設定
                  if (vlt.data.vehicle.battery_voltage.is_valid) {
                    context.commit('setvoltage', vlt.data.vehicle.battery_voltage.voltage);
                  } else {
                    context.commit('setvoltage', null);
                  }
                  context.commit('setStatus', util.createResultInfo(config.POLLING_COMPLETED));
                }, (result) => {
                  // 処理が失敗してる場合のコールバック処理
                  context.commit('setStatus',
                    util.createResultInfo(config.POLLING_FAILURE, result.message, result.behaviorType, result));
                }, null, false);
              }
              // リトライ上限超過
              if (++currentRetry === retryCount) {
                obj.instance.$stopInterval(watcher);
                const msg = obj.instance.$t(api.getErrorMessageForCode(Error.CLOUD_CONNECTOR_PAIRING_RSP_NOT_RECEIVED));
                context.commit('setStatus',
                  util.createResultInfo(config.POLLING_FAILURE, msg, handler.behaviorTypes.other));
                return;
              }
            } catch (err) {
              // 電圧情報の内部保持で予期しない失敗
              const msg = obj.instance.$t(api.getErrorMessageForCode(Error.CLOUD_CONNECTOR_PAIRING_RSP_NOT_RECEIVED));
              obj.instance.$stopInterval(watcher);
              context.commit('setStatus', util.createResultInfo(config.POLLING_FAILURE, msg, handler.behaviorTypes.other, err));
            }
          }, (result) => {
            // 車両情報取得に失敗
            obj.instance.$stopInterval(watcher);
            context.commit('setStatus',
              util.createResultInfo(config.POLLING_FAILURE, result.message, result.behaviorType, result));
          }, null, false);
        } catch (err) {
          // 電圧情報の内部保持で予期しない失敗
          const msg = obj.instance.$t(api.getErrorMessageForCode(Error.CLOUD_CONNECTOR_PAIRING_RSP_NOT_RECEIVED));
          obj.instance.$stopInterval(watcher);
          context.commit('setStatus', util.createResultInfo(config.POLLING_FAILURE, msg, handler.behaviorTypes.other, err));
        }
      }, Number(process.env.VUE_APP_VEHICLE_RETRY_INTERVAL), "updateVoltage " + '/netApps/' + obj.netAppId + '/vehicle/battery');

      // ポーリングの watcher ID を設定
      context.commit('setWatcher', watcher);
      // 進捗状態を設定(0%)
      // context.commit('setProgress', 0);
      // ポーリングの開始ステータスを設定
      context.commit('setStatus', util.createResultInfo(config.POLLING_RUNNING));
    },

    /* **
    * SystemsAPIの実行関数 
    * @param this: this を指定
    * @param netAppId: 接続した netAppId 
    */
    async runSystemApi(context, obj) {
      // systems API の実行状態を取得
      const isRun = context.rootState.vehicle.isExecutSystemsAPI;
      if (isRun) {
        return true;
      }
      // systemsAPIを実施した状態を設定
      context.commit('setIsExecutSystemsAPI', true);
      // systemsAPIの実行
      const systemsResponse = await api.postCall(config.REPRO, '/netApps/' + obj.netAppId + '/systems');
      const isSystemsSuccess = handler.validate(handler.validateTypes.all, systemsResponse, obj.this, null, null, (result) => {
        // エラーの場合はここで状態を設定する 
        context.commit('setStatus',
          util.createResultInfo(config.POLLING_FAILURE, result.message, result.behaviorType, result));
      });
      return isSystemsSuccess;
    },

    /* **
    * 電圧を設定する
    * @param payload: 電圧値
    */
    setvoltage(context, payload) {
      context.commit('setvoltage', payload);
    },

    /* **
    * 呼び出し元から任意の進捗パーセントを設定する
    * @param payload: 進捗パーセント
    */
    setProgress(context, payload) {
      context.commit('setProgress', payload);
    },

    setWarningQueue(context, payload) {
      context.commit('setWarnings', payload);
    },

    setStackedWarningQueue(context, payload) {
      context.commit('setStackedWarnings', payload);
    },

    setVehicleInfo(context, payload) {
      context.commit('setVehicle', payload);
    }
  }
};

const handleErrCodeList = (context, obj, errs) => {
  // 成功ステータスが err_code_list で返却された場合は除外
  const warnings = errs.filter(err => err !== 0 && err !== '0' && err !== null);
  const tmp = context.state.stackedWarnings;
  if (warnings.length > 0) {
    // すでにスタック済みの err_code_list のコードは state に設定しない
    // つまり新規の err_code_list の内容だけを呼び出し側の watch に通知させる
    const checkedErrs = tmp ? tmp.split(',') : [];
    const newErrs = [];
    for (const w of warnings) {
      if (!checkedErrs.includes(String(w))) newErrs.push(String(w));
    }
    if (newErrs.length > 0) {
      const sumErrs = tmp ? (tmp + ',' + newErrs.join(',')) : newErrs.join(',');
      context.commit('setStackedWarnings', sumErrs);    // 検知済みエラーコードの更新
      context.commit('setWarnings', newErrs.join(',')); // 新規エラーコードだけを state に設定
    }
    // ポーリング終了オプション確認
    return obj.options && obj.options.exitOpt;
  } else {
    return false;
  }
};
